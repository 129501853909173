import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";

const SecondaryHero = ({ title, description, buttonText, buttonUrl }) => {
	return (
		<div
			className="w-100 bg-light-blue py-7 text-center"
			style={{ overflow: "hidden" }}
		>
			<Container>
				<Row>
					<Col>
						<h1 className="display-3 text-primary">{title}</h1>
						<p className="text-secondary inter-regular fs-5">{description}</p>
						{buttonText && (
							<Button
								as={Link}
								to={buttonUrl}
								variant="primary"
								className=" text-center py-3 w-100 w-md-auto  text-white px-5 "
							>
								{buttonText}
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SecondaryHero;
