import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import moment from "moment";
import { useStaticQuery, graphql } from "gatsby";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const EventSection = () => {
	const data = useStaticQuery(graphql`
		query {
			events: allWpEvent(
				sort: { fields: eventFields___dateAndTime, order: ASC }
			) {
				nodes {
					title
					slug
					eventFields {
						dateAndTime
						heading
						extraDate
						extraDateAndTime
					}
				}
			}
		}
	`);

	const responsive = {
		0: {
			items: 2,
		},
		767: {
			items: 3,
		},
		991: {
			items: 4,
		},
	};

	const today = moment().startOf("day");

	const isEventUpcoming = (event) => {
		const startDate = moment(
			new Date(event.eventFields.dateAndTime.replace(" ", "T"))
		);
		const endDate = event.eventFields.extraDateAndTime
			? moment(new Date(event.eventFields.extraDateAndTime.replace(" ", "T")))
			: startDate;

		return endDate.isSameOrAfter(today);
	};

	const filteredEvents = data.events.nodes.filter(isEventUpcoming);

	const items = filteredEvents.map((event) => (
		<Col className="py-4  text-center">
			{event.eventFields.extraDate !== true && (
				<p className="inter-extrabold pb-0 mb-0 text-primary">
					{moment(
						new Date(event.eventFields.dateAndTime.replace(" ", "T"))
					).format("MMMM DD YYYY")}
				</p>
			)}
			{event.eventFields.extraDate === true && (
				<p className="inter-extrabold pb-0 mb-0 text-primary">
					{moment(
						new Date(event.eventFields.dateAndTime.replace(" ", "T"))
					).format("MMM DD")}{" "}
					-{" "}
					{moment(
						new Date(event.eventFields.extraDateAndTime.replace(" ", "T"))
					).format("MMM DD YYYY")}
				</p>
			)}
			<hr
				className="mx-auto bg-main-red py-0 my-1"
				style={{ opacity: 1, width: "4rem" }}
			/>

			<h3 style={{ minHeight: "48px" }} className="lato-bold fs-5 text-primary">
				{event.eventFields.heading}
			</h3>

			<Button
				as={Link}
				to={`/events/${event.slug}`}
				size="sm"
				variant="outline-primary px-3 mt-3 white-button"
			>
				Event Info
			</Button>
		</Col>
	));

	return (
		<div className="w-100 bg-mid-blue pb-5 position-relative">
			<Container style={{ zIndex: 1 }} className="position-relative">
				<Row className="justify-content-center">
					<Col xl={8}>
						<h2 className="text-center text-primary py-5 fs-1">
							Join us at one our upcoming events
						</h2>

						<Row className="justify-content-center">
							<AliceCarousel
								infinite={true}
								responsive={responsive}
								mouseTracking
								items={items}
								disableDotsControls
								autoPlay
								autoPlayInterval={2000}
							/>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default EventSection;
